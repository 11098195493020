/* eslint-disable no-unused-vars */
import { createUseStyles } from 'react-jss'

const useStyles = createUseStyles((theme) => ({
  containerFluid: {
    width: '100vw',
    padding: [[15, 20, 0]],
    overflow: 'hidden',
    overflowX: 'scroll',
    [`@media (min-width: ${theme.mediaQueries.mobile}px)`]: {
      overflowX: 'auto',
      padding: '0 20px',
    },
    [`@media (min-width: ${theme.mediaQueries.tablet}px)`]: {
      width: '100%',
    },
  },
  container: ({ maxNumberComparisonEcom }) => ({
    position: 'relative',
    minHeight: 800,
    margin: [[0, 'auto']],
    width: `calc(50vw * ${maxNumberComparisonEcom})`,
    paddingRight: 20,
    overflowX: 'auto',
    [`@media (min-width: ${theme.mediaQueries.mobile}px)`]: {
      width: '100%',
      paddingRight: 0,
      maxWidth: theme.mediaQueries.desktop,
    },
  }),
  containerEmpty: {
    margin: [[0, 'auto']],
    width: '100%',
    padding: '0 20px',
    [`@media (min-width: ${theme.mediaQueries.mobile}px)`]: {
      minHeight: 800,
      width: '100%',
    },
  },
  headerComparison: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    [`@media (min-width: ${theme.mediaQueries.mobile}px)`]: {
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
    },
  },
  buttonClear: {
    width: 'auto',
    padding: 0,
    textTransform: 'none',
    fontWeight: '400',
    textDecoration: 'underline',
    minHeight: '2.5rem',
    [`@media (min-width: ${theme.mediaQueries.mobile}px)`]: {
      minHeight: '5rem',
      width: 150,
      padding: '1rem 1rem',
      fontWeight: '400',
      textDecoration: 'underline',
    },
  },
  textItemsSelected: {
    color: theme.colors.text,
    fontSize: 16,
    lineHeight: 1,
    margin: 0,
  },
  isEmptyStyle: {
    marginTop: '1rem',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    '& p': {
      color: theme.colors.bgDark,
      marginTop: '3rem',
      marginBottom: '3rem',
    },
    '& button': {
      marginBottom: '3rem',
    },
    '& span': {
      display: 'block',
      position: 'relative',
      margin: 0,
      fontSize: '1.4rem',
      flexShrink: 1.14,
    },
    [`@media (min-width: ${theme.mediaQueries.mobile}px)`]: {
      marginTop: '3rem',

      '& p': {
        marginTop: '5rem',
        marginBottom: '6rem',
      },
    },
  },
  titleCardStyle: {
    marginBottom: 0,
    marginTop: '1.7rem',
    fontSize: '1.6rem',
    lineHeight: 1.25,
    textTransform: 'uppercase',
    fontWeight: 600,
    textAlign: 'center',
    [`@media (min-width: ${theme.mediaQueries.mobile}px)`]: {
      marginBottom: '0.7rem',
    },
    [`@media (min-width: ${theme.mediaQueries.tablet}px)`]: {
      textAlign: 'center',
      marginTop: '0.7rem',
    },
  },
  button: {
    width: '100%',
    [`@media (min-width: ${theme.mediaQueries.mobile}px)`]: {
      width: '37rem',
    },
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
  },
  rowProducts: {
    display: 'flex',
    flexDirection: 'row',
    transition: 'height 500ms',
    backgroundColor: theme.colors.background,
  },
  rowPictures: {
    display: 'none',
    height: 100,
    transition: 'height 500ms',
    backgroundColor: theme.colors.background,
    [`@media (min-width: ${theme.mediaQueries.tablet}px)`]: {
      height: 400,
    },
  },

  rowSticky: {
    display: 'block',
    transition: 'top 300ms',
    top: '60px !important',
    zIndex: 2,
    height: 100,
    boxShadow: '0 3px 4px -2px rgba(0, 0, 0, 0.1)',

    [`@media (min-width: ${theme.mediaQueries.tabletLandscape}px)`]: {
      top: '0 !important',
    },
  },
  botton: {
    top: '-100px !important',
    transition: 'top 300ms',
  },
  columnTitle: ({ maxNumberComparisonEcom }) => ({
    flex: '1 1 calc(100vw / 2 - 40px)',
    backgroundColor: '#f7f7f7',
    minHeight: '44px',
    display: 'flex',
    alignItems: 'center',
    alignContent: 'center',
    paddingLeft: 20,
    textTransform: 'capitalize',
    '& h4': {
      fontSize: 16,
      fontWeight: 600,
      margin: 0,
    },
    [`@media (min-width: ${theme.mediaQueries.mobile}px)`]: {
      maxWidth: `${25 * maxNumberComparisonEcom}%`,
      flex: '1 1 25%',
    },
  }),
  columnValue: {

    flex: '1 1 calc(100vw / 2 - 40px)',
    display: 'flex',
    paddingLeft: 20,
    minHeight: 56,
    alignItems: 'center',
    alignContent: 'center',
    borderRight: '2px solid #f7f7f7',
    '& p': {
      color: theme.colors.text,
      fontSize: 14,
      fontWeight: 400,
      margin: 0,
      lineHeight: 1.2,
    },
    '&:last-child': {
      borderRight: 'initial',
    },
    [`@media (min-width: ${theme.mediaQueries.mobile}px)`]: {
      maxWidth: '25%',
      flex: '1 1 25%',
    },
  },
  boxButton: {
    flex: '1 1 calc(100vw / 2 - 40px)',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 20,
    [`@media (min-width: ${theme.mediaQueries.mobile}px)`]: {
      maxWidth: '25%',
      flex: '1 1 25%',
    },
  },
  buttonView: {
    border: `1px solid ${theme.colors.primary}`,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 50,
    textDecoration: 'none',
  },
  textButton: {
    color: theme.colors.text,
    textTransform: 'uppercase',
    fontSize: 16,
    fontWeight: 500,
    margin: 0,
  },
  breadcrumb: {
    padding: '3rem 0 0',
    marginBottom: '1.7rem',
    [`@media (min-width: ${theme.mediaQueries.tablet}px)`]: {
      paddingTop: '2rem',
      marginBottom: '15px',
    },
  },
  shareGroup: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '& > div, & > h6': {
      margin: 0,
    },
  },
}))

export default useStyles
