/* eslint-disable no-unused-vars */
import { createUseStyles } from 'react-jss'
import ratioCalc from '../../../../helpers/ratioImage'
import getStyleFromTheme from '../../../../helpers/get-style-from-theme'

const useStyles = createUseStyles((theme) => ({
  container: {
    position: 'relative',
    // display: 'flex',
    // flexDirection: 'column',
  },
  containerSticky: {
    height: 100,
    // flexDirection: 'row',
  },
  boxImage: {
    display: 'block',
    width: '100%',
    height: '100%',
    backgroundColor: '#fff',
    transition: 'all 1500ms',
    margin: 0,
    [`@media (min-width: ${theme.mediaQueries.tablet}px)`]: {
      // height: 260,
    },
  },
  image: {
    aspectRatio: 'var(--pages-products-comparison-thumbnail-aspect-ratio)',
    position: 'relative',
    backgroundColor: '#dddddd',
    backgroundClip: 'content-box',
    overflow: 'hidden',
    '& img': {
      display: 'block',
      objectFit: 'cover',
      margin: 0,
      width: '100%',
    },
  },
  noImage: {
    backgroundClip: 'initial',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& img': {
      width: '40%',
    },
  },
  boxContent: {
    padding: [[0, 20]],
    top: 160,
    display: 'block',
    width: '100%',
    height: 100,
    transition: 'all 1000ms',
    textAlign: 'center',
    color: theme.colors.text,
    '& p, & p span': {
      marginBottom: '0',
      fontSize: '1.4rem',
      lineHeight: 1,
      margin: 0,
    },
    [`@media (min-width: ${theme.mediaQueries.mobile}px)`]: {
      '& p span': {
        marginBottom: '0',
        fontSize: '1.4rem',
        lineHeight: 1.4,
        margin: 0,
      },
    },
  },
  title: {
    fontSize: '1.6rem',
    color: theme.colors.text,
  },
  colorSelect: {
    margin: '5px 0',
    color: theme.colors.disabled,
  },
  colorSelectSticky: {
    display: 'none',
  },
  customLabels: {
    minHeight: '2rem',
    marginBottom: '0.4rem',
    marginTop: '1rem',
  },
  customLabelsSticky: {
    display: 'none',
  },
  originalPrice: {
    fontSize: '1.2rem',
    display: 'inline-block',
    textDecoration: 'line-through',
    color: theme.colors.disabled,
    lineHeight: 1.14,
    [`@media (min-width: ${theme.mediaQueries.tablet}px)`]: {
      marginRight: '0.4rem',
      display: 'inline',
      lineHeight: 1.8,
    },
  },
  sellPriceOnSale: {
    lineHeight: 1,
    color: theme.colors.alert,
    display: 'inline-block',
    [`@media (min-width: ${theme.mediaQueries.tablet}px)`]: {
      lineHeight: 1.8,
    },
  },
}))

export default useStyles
