import { createUseStyles } from 'react-jss'

const useStyles = createUseStyles((theme) => ({
  icon: {
    margin: 0,
    display: 'block',
    position: 'absolute',
    left: '1.4rem',
    top: '50%',
    color: theme.colors.text,
    transform: 'translateY(-50%)',
  },
  productCards: {
    flex: '1 1 calc(100vw / 2 - 40px)',
    position: 'relative',
    padding: '20px 15px',
    [`@media (min-width: ${theme.mediaQueries.mobile}px)`]: {
      maxWidth: '25%',
      flex: '1 1 25%',
    },
  },
  removeItem: {
    display: 'block',
    position: 'absolute',
    right: 26,
    top: 31,
    zIndex: 1,
  },
  removeItemButton: {
    backgroundColor: 'transparent',
    display: 'block',
  },
  removeItemButtonStick: {
    display: 'none',
  },
  emptyCards: {
    display: 'flex',
    flex: '1 1 calc(100vw / 2 - 40px)',
    padding: '20px 15px',
    transition: 'height 500ms',
    [`@media (min-width: ${theme.mediaQueries.mobile}px)`]: {
      maxWidth: '25%',
      flex: '1 1 25%',
    },
  },
  emptyCardsStick: {
    transition: 'height 500ms',
    height: 100,
  },
  boxEmptyCard: {
    position: 'relative',
    backgroundColor: '#f7f7f7',
    width: '100%',
    aspectRatio: 'var(--pages-products-comparison-thumbnail-aspect-ratio)',
    opacity: 1,
    transition: 'opacity 500ms',
  },
  boxEmptyCardStick: {
    transition: 'opacity 500ms',
    opacity: 0,
    pointerEvents: 'none',
  },
  contentEmptyCard: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    textDecoration: 'none',
    width: '100%',
  },
  textEmptyCard: {
    marginTop: 20,
    fontSize: 16,
    lineHeight: 1.2,
    textDecoration: 'none !important',
    color: theme.colors.text,
    margin: 0,
    textAlign: 'center',
  },
  textEmptyCardStick: {
    display: 'none',
  },
}))

export default useStyles
